import React, { useState, useEffect, useRef } from 'react';
import Layout from 'components/common/DashboardLayout';
import dummy from 'assets/dummy-avatar.png';
import { ChatHistory } from './chatHistory';
import { DocFolds } from './docFolds';
import './chat.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updatePrevLocation } from 'appRedux/actions/location';
import { Link } from '@reach/router';
import { FaArrowLeft } from 'react-icons/fa';
import { token } from 'appRedux/api';
import io from 'socket.io-client';
import {
  fetchVisaChatData,
  addNewVisaChat,
  fetchVisaChatDataByImage,
  fetchVisaChatDataByFile,
  fetchVisaChatDataByLink,
} from 'appRedux/actions/webVisaChat';
import { fetchUserData } from 'appRedux/actions/profile';

const url = 'https://api.wevesti.com/api/chats';

export const ChatFeature = () => {
  const dispatch = useDispatch();

  // const fileInputRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [extractedUrl, setExtractedUrl] = useState('');
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [newMessageListener, setNewMessageListener] = useState(false);
  const [newmsg, setNewMsg] = useState([]);
  const [replyChat, setReplyChat] = useState('');
  const [replyChatId, setReplyChatId] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileBinary, setSelectedFileBinary] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [viewImage, setViewImage] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [showUserNot, setShowUserNot] = useState(false);
  const [showImgPreview, setShowImgPreview] = useState(false);
  const [reconnect, setReconect] = useState(false);
  const [myUrl, setmyUrl] = useState('');

  useEffect(() => {
    dispatch(updatePrevLocation(prevPathname));
    dispatch(fetchUserData());
  }, [isConnected]);

  const userD = useSelector(state => state.auth?.userData);
  const prevPathname = useSelector(state => state.location.prevpathname);

  const application_id = localStorage.getItem('petitionId');

  const adminapplication_id = localStorage.getItem('petitionAdminId');

  const ImgInputRef = useRef(null);
  const fileInputRef = useRef(null);
  // const

  // remove selected file
  const removeFile = index => {
    setSelectedFile(prevFiles => prevFiles.filter((_, i) => i !== index));
    setSelectedFileBinary(prevBinaries =>
      prevBinaries.filter((_, i) => i !== index),
    );
  };

  const handleFileChange = event => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setSelectedFile(files.map(file => URL.createObjectURL(file)));
      setSelectedFileBinary(files);
    }
  };

  const handleIconClick = () => {
    ImgInputRef.current.click();
  };

  const handleFileIconClick = () => {
    fileInputRef.current.click();
  };

  const handleViewImage = imageUrl => {
    setViewImage(imageUrl);
    setOpenModal(true);
  };

  // To fetch chat,
  // Attorney is the reciever while the client logged in here is the sender ... but when sending message, the logged in user inboth senario is the sender ... dashboard and web

  useEffect(() => {
    dispatch(fetchVisaChatData(userD?.id, application_id));
    dispatch(fetchVisaChatDataByImage(userD?.id, application_id));
    dispatch(fetchVisaChatDataByFile(userD?.id, application_id));
    dispatch(fetchVisaChatDataByLink(userD?.id, application_id));
  }, [newMessageListener, isConnected]);

  const fetchAgain = () => {
    dispatch(fetchVisaChatData(userD?.id, application_id));
    dispatch(fetchVisaChatDataByImage(userD?.id, application_id));
    dispatch(fetchVisaChatDataByFile(userD?.id, application_id));
    dispatch(fetchVisaChatDataByLink(userD?.id, application_id));
  };

  useEffect(() => {
    const newSocket = io('https://api.wevesti.com/chats', {
      transports: ['websocket'],
      withCredentials: true,
      auth: {
        token: `Bearer ${token}`, // Pass the token in the auth object
      },
      // reconnectionDelay: 5000,
      // reconnectionDelayMax: 10000,
      reconnectionAttempts: 5,
    });

    newSocket.on('connect', () => {
      newSocket.emit('auth', {
        token: `Bearer ${token}`,
      });
      setIsConnected(true);
      console.log('Connected to WebSocket server');
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
      // window.location.reload();
      setIsConnected(false);
      setReconect(true);
    });

    newSocket.on('err', error => {
      console.error('WebSocket error:', error);
    });

    newSocket.on('auth', auth => {
      console.error('Auth:', auth);
    });

    newSocket.on('newMessage', async message => {
      // console.log(message);
      fetchAgain();
      setNewMessageListener(true);

      setIsConnected(true);

      dispatch(
        addNewVisaChat(
          JSON.parse(message).id
            ? JSON.parse(message)
            : JSON.parse(message).newChat,
        ),
      );
      setNewMsg(
        JSON.parse(message).id
          ? JSON.parse(message)
          : JSON.parse(message).newChat,
      );

      setMessages(prevMessages => [...prevMessages, message]);
      // setNewMessageListener(false);
    });

    newSocket.on('message', async message => {
      console.log(message);
      fetchAgain();
      setNewMessageListener(true);

      setIsConnected(true);

      dispatch(
        addNewVisaChat(
          JSON.parse(message).id
            ? JSON.parse(message)
            : JSON.parse(message).newChat,
        ),
      );
      setNewMsg(
        JSON.parse(message).id
          ? JSON.parse(message)
          : JSON.parse(message).newChat,
      );

      setMessages(prevMessages => [...prevMessages, message]);
      // setNewMessageListener(false);
    });

    setSocket(newSocket);

    // Cleanup function to disconnect on unmount
    return () => {
      newSocket.disconnect();
    };
  }, [reconnect, token]);

  // const handleSocketConnection = ()=>{

  // }

  const handlePrevSendMessage = async (newimgUrl, fileType, url) => {
    if (socket) {
      socket.emit('message-pathway-2', {
        message: inputValue,
        adminId: adminapplication_id,
        userId: userD?.id,
        image: newimgUrl && fileType == 'picture' ? newimgUrl : null,
        link: url ? url : null,
        file: newimgUrl && fileType == 'document' ? newimgUrl : null,
        // messageId: '21994a76-8136-4561-b980-9ee02dfeff52',
        quote: replyChat ? replyChat : null,
        action: replyChat ? 'quote' : 'message', //message or quote
        creator: 'user', //admin or user
        applicationId: application_id,
      });

      setReplyChat('');
      setInputValue('');
      setExtractedUrl('');
      setSelectedFile([]);
      setSelectedFileBinary([]);
      setProcessing(false);
      fetchAgain();
      // fetchAgain();
      // setIsConnected(true);
    }
  };

  const handleSendMessage = (newimgUrl, fileType) => {
    const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Regex to match URL
    const matchedUrls = inputValue.match(urlPattern); // Get matched URLs
    if (matchedUrls) {
      //  setExtractedUrl();
      // Store the first URL in state
      handlePrevSendMessage(newimgUrl, fileType, matchedUrls[0]);
    } else {
      handlePrevSendMessage(newimgUrl, fileType); // If no URL, reset state
    }
  };

  const handleImgUpload = async () => {
    setProcessing(true);
    const apiUrl = `
        https://api.wevesti.com/api/v1/customer/image-upload-aws`;
    const formData = new FormData();
    console.log(selectedFileBinary);
    selectedFileBinary.forEach((file, index) => {
      formData.append(`images`, file);
    });

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();
      const imgUrl = await data?.data[0];
      const fileType = selectedFileBinary[0].type.includes('image')
        ? 'picture'
        : 'document';

      if (imgUrl) {
        handleSendMessage(imgUrl, fileType);
      }

      return data;
    } catch (error) {
      setProcessing(false);
      console.error('Error uploading file:', error);
      return null;
    }
  };

  return (
    <Layout>
      <section className="somePd" style={{ height: '80svh' }}>
        <Link
          to={`${prevPathname ? prevPathname : `/pathways/visa`}`}
          style={{
            color: '#66A848',
            fontSize: '14px',
            marginBottom: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            fontWeight: '700',
            marginTop: '5px',
          }}
          onClick={() => {
            dispatch(updatePrevLocation(''));
          }}
        >
          <FaArrowLeft />
          Go back
        </Link>

        <p
          style={{
            paddingBottom: '0.5rem',
            fontSize: '22px',
            color: '#14290A',
            fontWeight: '600',
          }}
        >
          Conversations
        </p>

        <div className="VisaChatFeature">
          <div className="VisaChatFeature__chatsection">
            <>
              <ChatHistory
                messages={messages}
                setMessages={setMessages}
                handleSendMessage={handleSendMessage}
                inputValue={inputValue}
                setInputValue={setInputValue}
                newmsg={newmsg}
                setNewMsg={setNewMsg}
                replyChat={replyChat}
                setReplyChat={setReplyChat}
                handleViewImage={handleViewImage}
                removeFile={removeFile}
                selectedFile={selectedFile}
                selectedFileBinary={selectedFileBinary}
                handleIconClick={handleIconClick}
                ImgInputRef={ImgInputRef}
                handleImgUpload={handleImgUpload}
                handleFileChange={handleFileChange}
                fileInputRef={fileInputRef}
                handleFileIconClick={handleFileIconClick}
                processing={processing}
                setProcessing={setProcessing}
                setShowUserNot={setShowUserNot}
                showUserNot={showUserNot}
                myUrl={myUrl}
                setmyUrl={setmyUrl}
                // extractUrlFromSentence={extractUrlFromSentence}
                // onFileLoad={onFileLoad}
              />
            </>
          </div>

          <div className="VisaChatFeature__foldersection">
            <DocFolds
              messages={messages}
              setMessages={setMessages}
              setShowUserNot={setShowUserNot}
              showUserNot={showUserNot}
              myUrl={myUrl}
              setmyUrl={setmyUrl}
              showImgPreview={showImgPreview}
              setShowImgPreview={setShowImgPreview}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};
