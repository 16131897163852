import React from 'react';
import { Singlecard } from './singlecard';
// import { Cardactions } from "./cardactions";
import cfund from 'assets/cfund.svg';
import lock from 'assets/new-lock.svg';
import unlock from 'assets/lock-open.svg';
import fund from 'assets/vcards/fund.png';
import showDet from 'assets/vcards/fund (1).png';
import freeze from 'assets/vcards/fund (2).png';
import delCard from 'assets/vcards/fund (3).png';
// import cpin from "assets/cpin.svg"
// import cfreeze from "assets/freeze.svg"

// import climit from "assets/climit.svg"
import { Smalltitle } from 'components/common/titlesubtitle/titlesubtitle';
import { Clipboard } from 'components/common/clipboard/clipboard';
import { Virtualcard } from './singlecard';
export const CardtransInfo = () => {
  var data = [
    {
      name: '$1 - $80',
      fee: '$1',
    },
    {
      name: '$81 - $150',
      fee: '$2',
    },
    {
      name: '$151 - $500',
      fee: '$3',
    },
    {
      name: '$501 - $1000',
      fee: '$5',
    },
    {
      name: 'Above $1000',
      fee: '$5',
    },
  ];
  return (
    <section className="cardinfo">
      <span className="cardinfo__top">
        <p>Transaction Amount</p>
        <p>Transaction Fee</p>
      </span>
      {data.map((item, index) => (
        <span className="cardinfo__detail" key={index}>
          <p>{item.name}</p>
          <p>{item.fee}</p>
        </span>
      ))}
    </section>
  );
};

export const Carddesign = props => {
  if (props.name === 'Checkbook' || props.name === 'Mono') {
    return (
      <>
        <Virtualcard
          data={{ ...props.data, providerName: 'Mono' }}
          display="yes"
          details="Mycard"
        />
        <Cardbottom
          action={props.action}
          setAction={props.setAction}
          setpinModal={props.setpinModal}
          setLimit={props.setLimit}
          limitmodal={props.limitmodal}
          setOpen={props.setOpen}
          openModal={props.openModal}
          FreezeUnfreeze={props.FreezeUnfreeze}
          data={{
            ...props.data,
            providerName: 'Checkbook',
            cardColor: 'Black',
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <Singlecard data={props.data} display="yes" details="Mycard" />
        {props.name === 'Stripe' &&
          !props.userdata?.stripeVirtualAccountNumber && (
            <Cardbottom
              action={props.action}
              setAction={props.setAction}
              setpinModal={props.setpinModal}
              setLimit={props.setLimit}
              limitmodal={props.limitmodal}
              setOpen={props.setOpen}
              openModal={props.openModal}
              FreezeUnfreeze={props.FreezeUnfreeze}
              name={props.name}
              data={props.card}
            />
          )}
      </>
    );
  }
};

export const Cardbottom = props => {
  // console.log('Cardbottom : ', props);
  var actions = [
    { name: 'fund Card', img: fund, click: () => props.openModal() },
    { name: 'Show Details', img: showDet },
    {
      name: props.cardStatus === 'Card - Active' ? 'Freeze' : 'Unfreeze',
      img: freeze,
      // img: props.cardStatus === 'Card - Active' ? lock : unlock,
      click: () => props.FreezeUnfreeze(),
    },
    {
      name: 'Delete',
      img: delCard,
      click: () => {
        props.setAction(!props.action);
        // FreezeUnfreeze()
        props.data.cardStatus.includes('Inactive')
          ? props.FreezeUnfreeze()
          : props.setLimit({
              ...props.limitmodal,
              name: 'freeze',
              value: true,
            });
      },
    },
    // {name:'convert to ₦', img:climit, click:()=> props.openModal('NGN')},
    // {name:'PIN', img:cpin, click:() => { props.setAction(!props.action)
    //     props.setpinModal(true) }}
  ];
  return (
    <span className="cardactions">
      {props.data.providerName !== 'Stripe' && (
        <div className="cardactions__btns">
          {actions.map((item, index) => (
            <span className="" key={index} onClick={() => item.click()}>
              <img src={item.img} alt="action svg" style={{ width: '50px' }} />
              <p>{item.name}</p>
            </span>
          ))}
        </div>
      )}
    </span>
  );
};

export const Carddetails = props => {
  // console.log('Card detail:', props);
  var data = [
    {
      name: 'type',
      value: props.data.cardType,
    },
    {
      name: 'name',
      value:
        props.data.cardName ||
        props.data.cardObject.card_name ||
        props.data.cardHolderObject.card_name ||
        props.data.cardHolderObject.name,
    },
    {
      name: 'number',
      value: props.data.cardNumber
        ? props.data.cardNumber.toString().replace(/(\d{4})/g, '$1 ')
        : props.data.cardNumber,
    },
    {
      name: 'cvv',
      value: props.data.cardCVC,
    },
    {
      name: 'card status',
      value: props.data.cardStatus ? props.data.cardStatus : '',
    },
    {
      name: 'billing address',
      value: !props.data.cardAddress ? '' : props.data.cardAddress,
    },
    {
      name: 'Account Number',
      value: props.data.ddaNumber ? props.data.ddaNumber : '',
    },
    {
      name: 'routing number',
      value: props.data.routingNumber ? props.data.routingNumber : '',
    },
  ];
  var dataStripe = [
    {
      name: 'type',
      value: props.data.cardType,
    },
    // {
    //   name: 'name',
    //   value:
    //     props.data.cardObject.card_name ||
    //     props.data.cardHolderObject.card_name ||
    //     props.data.cardHolderObject.name,
    // },
    {
      name: 'Expiry Dates',
      value: props.data.cardExpMonth + '/' + props.data.cardExpYear,
    },
    {
      name: 'number',
      value: props.data.cardNumber
        ? props.data.cardNumber.toString().replace(/(\d{4})/g, '$1 ')
        : props.data.cardNumber,
    },
    {
      name: 'cvv',
      value: props.data.cardCVC,
    },

    {
      name: props.data.cardStatus ? 'card status' : '',
      value: props.data.cardStatus ? props.data.cardStatus : '',
    },
    {
      name: 'Billing address',
      value: '2909 E Arkansas Rd, Arlington, Texas, 76010',
    },
    // {
    //   name: props.userdata.stripeVirtualAccountNumber ? 'Account Number' : '',
    //   value: props.userdata.stripeVirtualAccountNumber
    //     ? props.userdata.stripeVirtualAccountNumber
    //     : '',
    // },
    // {
    //   name: props.userdata.stripeVirtualAccountRoutingNumber
    //     ? ' Account Routing Number'
    //     : '',
    //   value: props.userdata.stripeVirtualAccountRoutingNumber
    //     ? props.userdata.stripeVirtualAccountRoutingNumber
    //     : '',
    // },
    // {
    //   name: props.userdata.stripeVirtualBankName ? ' Bank Name' : '',
    //   value: props.userdata.stripeVirtualBankName
    //     ? props.userdata.stripeVirtualBankName
    //     : '',
    // },
    // {
    //    name:' Swift Code',
    //    value:props.userdata.stripeVirtualSwiftCode ? props.userdata.stripeVirtualSwiftCode:''
    // }
  ];
  return (
    <div className="carddetail">
      <div className="carddetail__bottom">
        {(props.data.providerName === 'Apto'
          ? data
          : props.data.providerName === 'Mono'
          ? data.slice(0, 7)
          : dataStripe
        ).map((item, index) => (
          <div
            key={index}
            className={`carddetail__bsingle ${
              item.name === 'card status'
                ? item.value?.includes('Inactive')
                  ? ' --inactive'
                  : ' --active'
                : ''
            }`}
          >
            <p className="--title">{item.name}</p>
            <Clipboard link={item.value} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const MonoCarddetails = props => {
  // const board = useRef(null);
  var data = [
    // {
    //     name:'type',
    //     value:'Virtual Card'
    // },
    // {
    //     name:'name',
    //     value:props.data.cardName
    // },
    {
      name: 'number',
      value: props.data.cardNumber,
    },
    {
      name: 'cvv',
      value: props.data.cardCVC,
    },
    {
      name: 'Expiry Date',
      value: props.data.cardExpMonth + ' / ' + props.data.cardExpYear,
    },
    {
      name: 'billing address',
      value: '1301 S BOWEN RD STE 450,Arlington,Texas, 76013',
    },
  ];

  return (
    <div className="carddetail">
      <Smalltitle title="Card Details" />
      <div className="carddetail__bottom">
        {(props.data.providerName === 'Apto' ? data : data.slice(0, 7)).map(
          (item, index) => (
            <div
              key={index}
              className={`carddetail__bsingle ${
                item.name === 'card status'
                  ? item.value.includes('Inactive')
                    ? ' --inactive'
                    : ' --active'
                  : ''
              }`}
            >
              <p className="--title">{item.name}</p>
              <Clipboard link={item.value} />
            </div>
          ),
        )}
      </div>
    </div>
  );
};
// eslint-disable-next-line
const Title = props => {
  return <p className="title">{props.title}</p>;
};
